import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { IReqBonusData } from "../../entities/bonus";

export const getBonuses = createAsyncThunk(
  'bonus/list',
  async () => {
    const req = await agent.get(`${apiUrlV1}/organization/bonus/list`);
    if (req.ok) {
      const res = await req.json() as IReqBonusData;
      return res.bonuses;
    }
    return [];
  }
);

export const createBonuse = createAsyncThunk(
  'bonus/added',
  async (params: { name: string, customerBonusId: string }, thunkApi) => {
    const { name, customerBonusId } = params
    const req = await agent.post(`${apiUrlV1}/organization/bonus`, {
      body: JSON.stringify({ name, customerBonusId })
    })
    if (req.ok) {
      thunkApi.dispatch(getBonuses());
    }
    if (!req.ok) {
      throw Error("Bonus with this name already exists");
    }
  }
)

export const updateBonuseById = createAsyncThunk(
  'bonus/update',
  async (params: { id: number, name: string, customerBonusId: string }, thunkApi) => {
    const { name, customerBonusId, id } = params
    const req = await agent.patch(`${apiUrlV1}/organization/bonus/${id}`, {
      body: JSON.stringify({ name, customerBonusId })
    })
    if (req.ok) {
      thunkApi.dispatch(getBonuses());
    }
    if (!req.ok) {
      throw Error("Bonus with this name already exists");
    }
  }
)