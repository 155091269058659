import { SvgIcon } from "@mui/material";

export const DiceIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 16.0013V7.9969C20.9997 7.73492 20.9308 7.47758 20.8001 7.25052C20.6694 7.02345 20.4815 6.83457 20.2552 6.70268L13.1302 2.55612C12.7869 2.35627 12.3968 2.25098 11.9995 2.25098C11.6023 2.25098 11.2122 2.35627 10.8689 2.55612L3.74484 6.70268C3.51848 6.83457 3.33061 7.02345 3.19993 7.25052C3.06925 7.47758 3.00032 7.73492 3 7.9969V16.0013C3.00016 16.2634 3.06901 16.5209 3.1997 16.7482C3.33038 16.9754 3.51834 17.1645 3.74484 17.2964L10.8698 21.443C11.2132 21.6426 11.6033 21.7477 12.0005 21.7477C12.3976 21.7477 12.7877 21.6426 13.1311 21.443L20.2561 17.2964C20.4824 17.1643 20.6702 16.9753 20.8007 16.748C20.9312 16.5208 20.9999 16.2633 21 16.0013Z"
          stroke="#6D6D6D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.23438 7.21875L12 12.375M12 12.375L20.7656 7.21875M12 12.375V21.75"
          stroke="#6D6D6D"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12 7.875C12.6213 7.875 13.125 7.53921 13.125 7.125C13.125 6.71079 12.6213 6.375 12 6.375C11.3787 6.375 10.875 6.71079 10.875 7.125C10.875 7.53921 11.3787 7.875 12 7.875Z"
          fill="#6D6D6D"
        />
        <path
          d="M9.75 15C10.1642 15 10.5 14.4963 10.5 13.875C10.5 13.2537 10.1642 12.75 9.75 12.75C9.33579 12.75 9 13.2537 9 13.875C9 14.4963 9.33579 15 9.75 15Z"
          fill="#6D6D6D"
        />
        <path
          d="M5.25 16.5C5.66421 16.5 6 15.9963 6 15.375C6 14.7537 5.66421 14.25 5.25 14.25C4.83579 14.25 4.5 14.7537 4.5 15.375C4.5 15.9963 4.83579 16.5 5.25 16.5Z"
          fill="#6D6D6D"
        />
        <path
          d="M14.25 15C14.6642 15 15 14.4963 15 13.875C15 13.2537 14.6642 12.75 14.25 12.75C13.8358 12.75 13.5 13.2537 13.5 13.875C13.5 14.4963 13.8358 15 14.25 15Z"
          fill="#6D6D6D"
        />
        <path
          d="M18.75 12.375C19.1642 12.375 19.5 11.8713 19.5 11.25C19.5 10.6287 19.1642 10.125 18.75 10.125C18.3358 10.125 18 10.6287 18 11.25C18 11.8713 18.3358 12.375 18.75 12.375Z"
          fill="#6D6D6D"
        />
        <path
          d="M14.25 19.125C14.6642 19.125 15 18.6213 15 18C15 17.3787 14.6642 16.875 14.25 16.875C13.8358 16.875 13.5 17.3787 13.5 18C13.5 18.6213 13.8358 19.125 14.25 19.125Z"
          fill="#6D6D6D"
        />
        <path
          d="M18.75 16.5C19.1642 16.5 19.5 15.9963 19.5 15.375C19.5 14.7537 19.1642 14.25 18.75 14.25C18.3358 14.25 18 14.7537 18 15.375C18 15.9963 18.3358 16.5 18.75 16.5Z"
          fill="#6D6D6D"
        />
      </svg>
    </SvgIcon>
  );
};
