import { Link, useLocation } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import styled from "@emotion/styled";

const BreadcrumbsBox = styled.div`
  width: 1680px;
  margin: 88px auto 24px auto;

  @media screen and (max-width: 1700px) {
    width: 98%;
  }
`;

const StyledLink = styled(Link)`
  color: #595e64;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.75px;
  text-decoration: none;
`;

export const BreadCrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <BreadcrumbsBox>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
        sx={{
          width: "1680px",
          margin: "0px auto",
          "@media screen and (max-width: 1700px)": {
            width: "98%",
          },
        }}
      >
        {pathnames.length !== 0 && <StyledLink to="/">Home</StyledLink>}
        {pathnames.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
          const isLast = index === pathnames.length - 1;

          return isLast ? (
            <StyledLink key={name} to={routeTo}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </StyledLink>
          ) : (
            <StyledLink key={name} to={routeTo}>
              {name.charAt(0).toUpperCase() + name.slice(1)}
            </StyledLink>
          );
        })}
      </Breadcrumbs>
    </BreadcrumbsBox>
  );
};
