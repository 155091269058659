import { CssBaseline, ThemeProvider } from '@mui/material'
import { GamixterTheam } from './Theme/AdminTheme';
import { Provider } from 'react-redux';
import store from './store';
import { RouteOnAuth } from './navigation/AdminRouter';



function GamixterAdminApp() {
  return (
    <ThemeProvider theme={GamixterTheam}>
      <CssBaseline />
      <Provider store={store}>
        <RouteOnAuth />
      </Provider>
    </ ThemeProvider>
  );
}

export default GamixterAdminApp;
