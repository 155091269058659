import styled from "@emotion/styled";

export const SectionAuth = styled.section`
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;
export const AuthBox = styled.div`
  padding: 40px;
  width: 380px;
  box-shadow: 0px 17px 42px 0px #0e00200d;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AuthTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  line-height: 28.13px;
  color: #434343;
  margin: 0px 0px 24px 0px;
`;