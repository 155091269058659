import { Modal } from "@mui/material";
import React from "react";
import { NavModalLogout } from "../Components/NavModal/NavModalLogout";
import { NavMenu } from "../Components/NavMenu/NavMenu";
export const NavBarInProfile = () => {
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  return (
    <>
      <div>
        <NavMenu
          handlerModalOpen={() => {
            setOpenModal(true);
          }}
        />
      </div>
      <Modal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        aria-labelledby="admin-modal-title"
        aria-describedby="admin-modal-description"
      >
        <NavModalLogout
          onClose={() => {
            setOpenModal(false);
          }}
        />
      </Modal>
    </>
  );
};
