import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { firebaseAuthUser, userLogOut } from "./thunks";
import UserProfile from "../../entities/userProfile";

interface AuthState {
  token: string | null;
  profile: UserProfile | null;
  loading: boolean;
  errorText?: string;
}

const localStorageToken: string | null = localStorage.getItem('token') || null;

const initialState: AuthState = {
  token: localStorageToken,
  profile: null,
  loading: !!localStorageToken,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action: PayloadAction<UserProfile>) => {
      state.profile = action.payload
    },
    logOut: (state) => {
      state.profile = null;
      state.token = null;
      localStorage.removeItem('token');
      localStorage.removeItem('refresh');
    },
    refreshToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem('token', action.payload);
    },
    clearErrors: (state) => { state.errorText = undefined; },
  },
  extraReducers: (builder) => {
    builder.addCase(firebaseAuthUser.pending, (state: Draft<AuthState>) => {
      state.loading = true;
    });
    builder.addCase(firebaseAuthUser.fulfilled, (state: Draft<AuthState>, action) => {
      state.profile = action.payload;
      state.loading = false;
    });
    builder.addCase(firebaseAuthUser.rejected, (state: Draft<AuthState>) => {
      state.errorText = 'Invalid login or password';
      state.loading = false;
    })
    builder.addCase(userLogOut.fulfilled, (state: Draft<AuthState>) => {
      state.loading = false;
      state.token = null;
      state.profile = null;
    });
  }
});

export const { login, logOut, clearErrors, refreshToken } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;