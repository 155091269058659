import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent, encodeGetParams } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { IReqTriggers, ITriggerData } from "../../entities/trigger";

export const getTriggers = createAsyncThunk(
  'trigger/list',
  async (): Promise<ITriggerData[]> => {

    const req = await agent.get(`${apiUrlV1}/organization/trigger/list${encodeGetParams({ page: 1, limit: 250 })}`);
    if (req.ok) {
      const res: IReqTriggers = await req.json();
      return res.triggerList;
    }
    return [];
  }
);
