import { SvgIcon } from "@mui/material";

export const BoltIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.67 21.0003C10.32 21.0003 10.05 20.6903 10.1 20.3403L11 14.0003H7.49997C6.61997 14.0003 7.16997 13.2503 7.18997 13.2203C8.44997 10.9903 10.34 7.69033 12.84 3.29033C12.9073 3.17487 13.0126 3.08636 13.1379 3.0399C13.2632 2.99344 13.4007 2.99191 13.527 3.03559C13.6533 3.07926 13.7606 3.16543 13.8304 3.27937C13.9002 3.39331 13.9284 3.52796 13.91 3.66033L13.01 10.0003H16.52C16.92 10.0003 17.14 10.1903 16.92 10.6603C13.63 16.4003 11.72 19.7503 11.17 20.7103C11.07 20.8903 10.88 21.0003 10.67 21.0003Z"
          fill="#6D6D6D"
        />
      </svg>
    </SvgIcon>
  );
};
