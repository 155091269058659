import { InfoIcon } from "../Svg/InfoIcon";
import { Box, Button, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../store";
import { userLogOut } from "../../store/Auth/thunks";
import React from "react";

const ModalLogOutBox = styled(Box, {
  name: "ModalLogOutBox",
  slot: "root",
})(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 426,

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "40px",
  borderRadius: "8px",
  background: "#FFFFFF",
  boxShadow: " 0px 2px 12px 0px #0000001A",
}));

interface INavModalLogout {
  onClose: () => void;
}

export const NavModalLogout = React.forwardRef(
  (props: INavModalLogout, ref) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    return (
      <ModalLogOutBox ref={ref} tabIndex={-1}>
        <InfoIcon />
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 500,
            lineHeight: "22px",
            textAlign: "center",
            mb: "24px",
            mt: "11px",
          }}
          color={"#2F363D"}
        >
          {t("Header.logout-modal-text")}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "repeat(2,calc(50% - 8px))",
            gap: "16px",
            width: "100%",
          }}
        >
          <Button
            fullWidth
            variant="contained"
            onClick={() => {
              dispatch(userLogOut());
            }}
            sx={{
              boxShadow: "none",
              "&:hover, &:focus": {
                boxShadow: "none",
              },
            }}
          >
            {t("Header.logout")}
          </Button>
          <Button
            fullWidth
            variant="outlined"
            onClick={() => {
              props.onClose();
            }}
          >
            {t("cancel")}
          </Button>
        </Box>
      </ModalLogOutBox>
    );
  }
);
