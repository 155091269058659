import { SvgIcon } from "@mui/material";

export const UserIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 12C13.2815 11.9685 14.344 11.531 15.1875 10.6875C16.031 9.844 16.4685 8.7815 16.5 7.5C16.4685 6.2185 16.031 5.156 15.1875 4.3125C14.344 3.469 13.2815 3.0315 12 3C10.7185 3.0315 9.656 3.469 8.8125 4.3125C7.969 5.156 7.5315 6.2185 7.5 7.5C7.5315 8.7815 7.969 9.844 8.8125 10.6875C9.656 11.531 10.7185 11.9685 12 12ZM12 13.5C10.297 13.453 8.883 12.867 7.758 11.742C6.633 10.617 6.047 9.203 6 7.5C6.047 5.797 6.633 4.383 7.758 3.258C8.883 2.133 10.297 1.547 12 1.5C13.703 1.547 15.117 2.133 16.242 3.258C17.367 4.383 17.953 5.797 18 7.5C17.953 9.203 17.367 10.617 16.242 11.742C15.117 12.867 13.703 13.453 12 13.5ZM19.5 21V18.75C19.4845 18.1095 19.2657 17.5782 18.8438 17.1562C18.4218 16.7343 17.8905 16.5155 17.25 16.5H6.75C6.1095 16.5155 5.57825 16.7343 5.15625 17.1562C4.73425 17.5782 4.5155 18.1095 4.5 18.75V21C4.5 21.219 4.42975 21.3987 4.28925 21.5392C4.14875 21.6797 3.969 21.75 3.75 21.75C3.531 21.75 3.35125 21.6797 3.21075 21.5392C3.07025 21.3987 3 21.219 3 21V18.75C3.0315 17.6875 3.39875 16.8047 4.10175 16.1017C4.80475 15.3987 5.6875 15.0315 6.75 15H17.25C18.3125 15.0315 19.1953 15.3987 19.8983 16.1017C20.6013 16.8047 20.9685 17.6875 21 18.75V21C21 21.219 20.9297 21.3987 20.7892 21.5392C20.6487 21.6797 20.469 21.75 20.25 21.75C20.031 21.75 19.8513 21.6797 19.7108 21.5392C19.5703 21.3987 19.5 21.219 19.5 21Z"
          fill="#6D6D6D"
        />
      </svg>
    </SvgIcon>
  );
};
