export const colors = {
  white: "#ffffff",
  black: "#000000",
  basicGray: "#F5F6F8",
  gray: {
    50: "#F6F6F6",
    100: "#E7E7E7",
    200: "#D1D1D1",
    300: "#B0B0B0",
    400: "#888888",
    500: "#6D6D6D",
    600: "#5D5D5D",
    700: "#4F4F4F",
    800: "#454545",
    900: "#3D3D3D",
    950: "#222222",
  },
  blue: {
    50: "#EFF3FF",
    100: "#DCE3FD",
    200: "#C0CEFD",
    300: "#95AFFB",
    400: "#597EF7",
    500: "#3E5CF3",
    600: "#283BE8",
    700: "#2028D5",
    800: "#2023AD",
    900: "#202588",
    950: "#181953",
  },
  red: {
    50: "#FDE3E3",
    100: "#FDCBCB",
    200: "#FAA7A7",
    300: "#F56C6C",
    400: "#F56C6C",
    500: "#EC4747",
    600: "#D92929",
    700: "#B61F1F",
    800: "#971D1D",
    900: "#7D1F1F",
    950: "#440B0B",
  },
  green: {
    50: "#E6F4E4",
    100: "#CEE8CA",
    200: "#A9D4A1",
    300: "#7BB870",
    400: "#F5FAF3",
    500: "#5AA04E",
    600: "#447F3A",
    700: "#376530",
    800: "#2F512A",
    900: "#284324",
    950: "#11240F",
  },
  orange: {
    50: "#FFF6E8",
    100: "#FFEDD1",
    200: "#FFE3BA",
    300: "#FFDAA3",
    400: "#FFFAF3",
    500: "#FFD18C",
    600: "#FFC875",
    700: "#FFBF5E",
    800: "#FFB547",
    900: "#FFAC30",
    950: "#FFA318",
  },
};
