import { createSlice} from "@reduxjs/toolkit";
import { RootState } from "..";
import { IJackpotProfile } from "../../entities/jackpotGame";

interface JackpotState {
  list: {
    profiles: IJackpotProfile[],
  },
  profile?: {
    global: IJackpotProfile
  }
}

const initialState: JackpotState = {
  list: {
    profiles: [],
  },
}

export const jackpotSlice = createSlice({
  name: "jackpotGame",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {

  },
});

// export const { } = jackpotSlice.actions;

export const selectJackpotList = (state: RootState) => state.jackpot.list;

export default jackpotSlice.reducer;
