import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent, encodeGetParams } from "../../api";
import { apiUrlV1 } from "../../config/config";
import {
  IWheelPrize,
  IReqWheelList,
  IDatabaseWheel,
  IFormPrizeCreate,
  IReqProfilesWheel,
  IProfilesWheel,
  IGlobalProfile,
  IWheelConfige,
  ITranslationProfile,
  ImagePrize,
} from "../../entities/wheelGame";

const DEFAULT_URL = `${apiUrlV1}/organization/game/fortunewheel/`;

export const getPrizeWheel = createAsyncThunk(
  "wheel/list",
  async (): Promise<IDatabaseWheel[]> => {
    const query = encodeGetParams({ page: 1, limit: 250 });
    const req = await agent.get(`${DEFAULT_URL}prize/list${query}`);
    if (req.ok) {
      const res: IReqWheelList = await req.json();
      return res.gameEntities;
    }
    return [];
  }
);

export const getPrizeById = createAsyncThunk(
  "wheel/get-prize-by-id",
  async (id: string) => {
    const res = await agent.get(`${DEFAULT_URL}prize/${id}`);
    if (res.ok) {
      const result = await res.json();
      return result.prize;
    }
  }
);

export const getProfilesWheel = createAsyncThunk(
  "wheel/get-profile",
  async (): Promise<IProfilesWheel[]> => {
    const res = await agent.get(`${DEFAULT_URL}profile/list/`);

    if (res.ok) {
      const body = (await res.json()) as IReqProfilesWheel;
      return body.list;
    }
    return [];
  }
);

const updateImages = createAsyncThunk(
  "wheel/images-loadded",
  async (props: { array: ImagePrize[], id: number, tag: "imageFile" | "prizeFile" }) => {
    const { array, id, tag } = props


    await Promise.all(
      array.map(async (image) => {
        const formData = new FormData();
        formData.append("file", image.file);
        formData.append("lang", image.lang);
        formData.append("tag", tag);

        const response = await agent.post(
          `${DEFAULT_URL}prize/upload-translation/${id}`,
          {
            method: "POST",
            body: formData,
          }
        );
        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
      })
    );
  }
)

export const newCreatePrizeWheel = createAsyncThunk(
  "wheel/new-create",
  async (props: IFormPrizeCreate, thunkApi) => {
    const body: IWheelPrize = {
      name: props.name,
      config: {
        jackpot: (props.jackpot === 'true'),
      },
      bonusId: Number(props.bonusId),
      langs: props.language,
    };
    try {
      const req = await agent.post(`${DEFAULT_URL}prize`, {
        body: JSON.stringify(body),
      });
      if (req.ok) {
        const res = await req.json();
        const newPrizeId = res.id;
        const { images, winImages } = props;
        if (images.length > 0)
          thunkApi.dispatch(updateImages({ array: images, id: Number(newPrizeId), tag: 'imageFile' }));
        if (winImages.length > 0)
          thunkApi.dispatch(updateImages({ array: winImages, id: Number(newPrizeId), tag: 'prizeFile' }));
        thunkApi.dispatch(getPrizeWheel());
      }
      if (!req.ok) {
        const error = await req.json();
        return thunkApi.rejectWithValue(error);
      }
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const updatePrizeWheel = createAsyncThunk(
  "wheel/update-prize",
  async ({ data, id }: { data: IFormPrizeCreate; id: number }, thunkApi) => {
    const body: IWheelPrize = {
      name: data.name,
      config: {
        jackpot: (data.jackpot === 'true'),
      },
      bonusId: Number(data.bonusId),
      langs: data.language,
    };
    const req = await agent.patch(`${DEFAULT_URL}prize/${id}`, {
      body: JSON.stringify(body),
    });
    if (req.ok) {

      const { images, winImages } = data;
      if (images.length > 0) {
        const array = images.filter((item) => typeof (item.file) !== 'string')
        thunkApi.dispatch(updateImages({ array, id, tag: 'imageFile' }));
      }
      if (winImages.length > 0) {
        const array = winImages.filter((item) => typeof (item.file) !== 'string')
        thunkApi.dispatch(updateImages({ array, id, tag: 'prizeFile' }));
      }

      thunkApi.dispatch(getPrizeById(id.toString()));
      thunkApi.dispatch(getPrizeWheel());
    }
    if (!req.ok) {
      throw new Error(`HTTP error ${req.status}`)
    }

  }
);

export const deletePrizeById = createAsyncThunk(
  "wheel/delete-prize",
  async (id: number, thunkApi) => {
    const response = await agent.delete(`${DEFAULT_URL}prize/${id}`);
    if (response.ok) {
      thunkApi.dispatch(getPrizeWheel());
    }
  }
);

interface IProfile {
  global: IGlobalProfile | null;
  config: IWheelConfige[];
  translations: ITranslationProfile[];
}

export const getProfile = createAsyncThunk(
  `wheel-profile/get`,
  async (profileId: number) => {
    const [global, config, translation] = await Promise.all([
      await agent.get(`${DEFAULT_URL}profile/${profileId}`),
      await agent.get(
        `${DEFAULT_URL}profile/config/list?profileId=${profileId}`
      ),
      await agent.get(`${DEFAULT_URL}profile/translation/list/${profileId}`),
    ]);
    let response: IProfile = { global: null, config: [], translations: [] };
    if (global.ok) {
      response = { ...response, global: await global.json() };
    }
    if (config.ok) {
      const data = (await config.json()) as {
        count: number;
        list: IWheelConfige[];
      };
      response = { ...response, config: data.list };
    }
    if (translation.ok) {
      const data = (await translation.json()) as {
        list: ITranslationProfile[];
      };
      response = { ...response, translations: data.list };
    }

    return response;
  }
);

export const createGlobalProfile = createAsyncThunk(
  "wheel/create-profile",
  async (data: IGlobalProfile, thunkApi) => {
    const res = await agent.post(`${DEFAULT_URL}profile`, {
      body: JSON.stringify(data),
    });

    if (res.ok) {
      const result = (await res.json()) as { profileId: number };
      thunkApi.dispatch(getProfile(result.profileId));
      thunkApi.dispatch(getProfilesWheel());
    }

    if (!res.ok) {
      throw new Error(`HTTP error ${res.status}`);
    }
  }
);

export const updateGlobalProfile = createAsyncThunk(
  "wheel/update-profile",
  async (data: IGlobalProfile, thunkApi) => {
    const body = {
      triggerId: data.triggerId,
      name: data.name,
      primaryLang: data.primaryLang,
      langs: data.langs,
      status: data.status,
    };
    const res = await agent.patch(`${DEFAULT_URL}profile/${data.id}`, {
      body: JSON.stringify(body),
    });

    if (res.ok) {
      const body = (await res.json()) as { profileId: number };
      thunkApi.dispatch(getProfile(body.profileId));
      thunkApi.dispatch(getProfilesWheel());
    }

    if (!res.ok) {
      throw new Error(`HTTP error ${res.status}`);
    }
  }
);

export const getConfigWheelById = createAsyncThunk(
  "wheel/config-wheel",
  async (id: number) => {
    const query = encodeGetParams({ profileId: id });
    const res = await agent.get(`${DEFAULT_URL}profile/config/list${query}`);

    if (res.ok) {
      const body = (await res.json()) as {
        count: number;
        list: IWheelConfige[];
      };
      if (body.list.length > 12) {
        return body.list.slice(0, 12);
      } else {
        return body.list;
      }
    }
    return [];
  }
);

export const setConfigWheel = createAsyncThunk(
  "wheel/create-config-wheel",
  async (props: IWheelConfige[], thunkApi) => {
    const req = await agent.post(`${DEFAULT_URL}profile/config`, {
      body: JSON.stringify({ configList: props }),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunkApi.dispatch(getProfile(res.id));
    }
  }
);

export const updateConfigWheelById = createAsyncThunk(
  "wheel/update-config-wheel",
  async ({ data, id }: { id: number; data: IWheelConfige[] }, thunkApi) => {
    const req = await agent.patch(`${DEFAULT_URL}profile/config/${id}`, {
      body: JSON.stringify({ configList: data }),
    });
    if (req.ok) {
      const res = (await req.json()) as { id: number };
      thunkApi.dispatch(getProfile(res.id));
    }
  }
);

export const getPopupSettings = createAsyncThunk(
  "wheel/get-popup-list",
  async (id: number) => {
    const res = await agent.get(`${DEFAULT_URL}profile/translation/list/${id}`);

    if (res.ok) {
      const body = await res.json();
      return body.list;
    }
    return [];
  }
);

export const setTranslations = createAsyncThunk(
  "wheel/set-popup-list",
  async (data: ITranslationProfile[], thunkApi) => {
    await Promise.all(
      data.map(async (item) => {
        const response = await agent.post(
          `${DEFAULT_URL}profile/translation/${item.profileId}`,
          {
            body: JSON.stringify(item),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
      })
    );
    thunkApi.dispatch(getProfile(data[0].profileId));
  }
);

export const updateTranslations = createAsyncThunk(
  "wheel/update-popup-list",
  async (data: ITranslationProfile[], thunkApi) => {
    const patch = data.filter((item) => !!item.id);

    await Promise.all(
      patch.map(async (item) => {
        const response = await agent.patch(
          `${DEFAULT_URL}profile/translation/${item.profileId}`,
          {
            body: JSON.stringify(item),
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error ${response.status}`);
        }
      })
    );

    const post = data.filter((item) => !item.id);
    if (post.length !== 0) {
      thunkApi.dispatch(setTranslations(post));
    }

    thunkApi.dispatch(getProfile(data[0].profileId));
  }
);
