import React from "react";
import { Layout } from "../Layout/Layout";
import { createBrowserRouter, redirect } from "react-router-dom";

const MainPage = React.lazy(
  async () => await import("../Pages/Games/GamesPage")
);
const TriggerPage = React.lazy(
  async () => await import("../Pages/Trigger/TriggerPage")
);
const BonusPage = React.lazy(
  async () => await import("../Pages/Bonus/BonusPage")
);
const FortuneWheelPage = React.lazy(
  async () => await import("../Pages/FortuneWheel/FortuneWheel")
);
const IntegrationPage = React.lazy(
  async () => await import("../Pages/Integration/IntegrationPage")
);
const PrizePage = React.lazy(
  async () => await import("../Pages/FortuneWheel/Prize/PrizePage")
);
const JackpotAggregatorPage = React.lazy(
  async () => await import("../Pages/JackpotAggregator/JackpotAggregatorPage")
);
const JackpotProfilePage = React.lazy(
  async () => await import("../Pages/JackpotAggregator/Profile/ProfilePage")
);
const JackpotDatabasePage = React.lazy(
  async () => await import("../Pages/JackpotAggregator/Database/DatabasePage")
);

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Layout>
        <MainPage />
      </Layout>
    ),
  },
  {
    path: "/bonus",
    element: (
      <Layout>
        <BonusPage />
      </Layout>
    ),
  },
  {
    path: "/trigger",
    element: (
      <Layout>
        <TriggerPage />
      </Layout>
    ),
  },
  {
    path: "/game",
    loader: async () => {
      // Здесь вы можете получить значение :table из базы данных
      return redirect(`/`);
    },
  },
  {
    path: "/integrations",
    element: (
      <Layout>
        <IntegrationPage />
      </Layout>
    ),
  },
  {
    path: "/game/fortuneWheel/",
    loader: async () => {
      // Здесь вы можете получить значение :table из базы данных
      const table = "database";
      return redirect(`/game/fortuneWheel/${table}`);
    },
  },
  {
    path: "/game/fortuneWheel/:table/",
    element: (
      <Layout>
        <FortuneWheelPage />
      </Layout>
    ),
  },
  {
    path: "/game/fortuneWheel/prize/:id/edit",
    element: (
      <Layout>
        <PrizePage />
      </Layout>
    ),
  },
  {
    path: "/game/fortuneWheel/prize/create",
    element: (
      <Layout>
        <PrizePage />
      </Layout>
    ),
  },
  {
    path: "game/jackpot/",
    loader: async () => {
      const table = "database";
      return redirect(`/game/jackpot/${table}`);
    },
  },
  {
    path: "game/jackpot/:table/",
    element: (
      <Layout>
        <JackpotAggregatorPage />
      </Layout>
    ),
  },
  {
    path: "game/jackpot/profile/create",
    element: (
      <Layout>
        <JackpotProfilePage />
      </Layout>
    ),
  },
  {
    path: "game/jackpot/profile/:id/edit",
    element: (
      <Layout>
        <JackpotProfilePage />
      </Layout>
    ),
  },
  {
    path: "game/jackpot/database/create",
    element: (
      <Layout>
        <JackpotDatabasePage />
      </Layout>
    ),
  },
  {
    path: "game/jackpot/database/:id/edit",
    element: (
      <Layout>
        <JackpotDatabasePage />
      </Layout>
    ),
  },
]);
