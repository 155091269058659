import { SvgIcon } from "@mui/material";

export const InfoIcon = () => {
  return (
    <SvgIcon sx={{ width: "48px", height: "48px" }}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="42"
        height="42"
        viewBox="0 0 42 42"
        fill="none"
      >
        <path
          d="M21 0C26.938 0.156 31.8835 2.2105 35.8365 6.1635C39.7895 10.1165 41.844 15.062 42 21C41.844 26.938 39.7895 31.8835 35.8365 35.8365C31.8835 39.7895 26.938 41.844 21 42C15.062 41.844 10.1165 39.7895 6.1635 35.8365C2.2105 31.8835 0.156 26.938 0 21C0.156 15.062 2.2105 10.1165 6.1635 6.1635C10.1165 2.2105 15.062 0.156 21 0ZM24.141 12.891C24.954 12.891 25.626 12.649 26.157 12.165C26.688 11.681 26.9535 11.0325 26.9535 10.2195C26.9535 9.4065 26.688 8.758 26.157 8.274C25.626 7.79 24.962 7.548 24.165 7.548C23.368 7.548 22.704 7.79 22.173 8.274C21.642 8.758 21.3765 9.4065 21.3765 10.2195C21.3765 11.0325 21.642 11.681 22.173 12.165C22.704 12.649 23.3605 12.891 24.1425 12.891H24.141ZM24.7035 29.766C24.7035 29.578 24.719 29.328 24.75 29.016C24.781 28.704 24.781 28.407 24.75 28.125L22.266 30.984C22.016 31.265 21.758 31.484 21.492 31.641C21.226 31.798 20.9995 31.845 20.8125 31.782C20.5315 31.657 20.4065 31.438 20.4375 31.125L24.5625 18.141C24.7185 17.266 24.578 16.516 24.141 15.891C23.704 15.266 23.001 14.891 22.032 14.766C20.938 14.797 19.7425 15.258 18.4455 16.149C17.1485 17.04 16.0155 18.173 15.0465 19.548V20.2515C15.0155 20.5645 15.0155 20.8615 15.0465 21.1425L17.5305 18.2835C17.7805 18.0025 18.0385 17.7835 18.3045 17.6265C18.5705 17.4695 18.7815 17.4225 18.9375 17.4855C19.2505 17.6415 19.36 17.8915 19.266 18.2355L15.1875 31.173C14.9685 31.954 15.078 32.6495 15.516 33.2595C15.954 33.8695 16.7195 34.2835 17.8125 34.5015C19.3755 34.4705 20.688 34.0175 21.75 33.1425C22.812 32.2675 23.7965 31.1425 24.7035 29.7675V29.766Z"
          fill="#597EF7"
        />
      </svg>
    </SvgIcon>
  );
};
