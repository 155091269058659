import { IconButton, TextField } from "@mui/material";
import { Field, Form, FormSpy } from "react-final-form";
import ErrorIcon from "@mui/icons-material/Error";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import React from "react";
import { colors } from "../../Theme/ColorsForTheam";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store";
import { firebaseAuthUser } from "../../store/Auth/thunks";
import { clearErrors, selectAuthState } from "../../store/Auth/authStore";
import { LoadingButton } from "@mui/lab";

export const AuthForm = () => {
  const { t } = useTranslation();
  const initial = { login: "", password: "" };
  const { errorText, loading } = useAppSelector(selectAuthState);
  const [showPasssword, setShowPassord] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onSubmitForm = async (data: { login: string; password: string }) => {
    const body = { login: data.login, password: data.password };
    dispatch(firebaseAuthUser(body));
  };

  return (
    <Form<{ login: string; password: string }>
      initialValues={initial}
      onSubmit={onSubmitForm}
      render={({ handleSubmit, submitting, pristine }) => (
        <form onSubmit={handleSubmit} style={{ width: "100%" }}>
          <div>
            <Field
              name="login"
              type="email"
              render={({ input, meta }) => (
                <TextField
                  {...input}
                  placeholder={t("Auth.login")}
                  variant="outlined"
                  fullWidth
                  focused={false}
                  sx={{ marginBottom: "8px" }}
                  InputProps={{
                    endAdornment: errorText && (
                      <ErrorIcon
                        fontSize="small"
                        sx={{ color: colors.red[600] }}
                      />
                    ),
                  }}
                  error={!!errorText}
                />
              )}
            />
          </div>
          <div>
            <Field
              name="password"
              type={showPasssword ? "text" : "password"}
              render={({ input, meta }) => (
                <TextField
                  {...input}
                  placeholder={t("Auth.password")}
                  variant="outlined"
                  fullWidth
                  focused={false}
                  sx={{ marginBottom: "32px" }}
                  InputProps={
                    true && {
                      endAdornment: !errorText ? (
                        showPasssword ? (
                          <IconButton
                            size="small"
                            onClick={() => {
                              setShowPassord(false);
                            }}
                          >
                            <VisibilityOutlinedIcon
                              fontSize="small"
                              sx={{ color: colors.gray[400] }}
                            />
                          </IconButton>
                        ) : (
                          <IconButton
                            size="small"
                            onClick={() => {
                              setShowPassord(true);
                            }}
                          >
                            <VisibilityOffOutlinedIcon
                              fontSize="small"
                              sx={{ color: colors.gray[400] }}
                            />
                          </IconButton>
                        )
                      ) : (
                        <ErrorIcon
                          fontSize="small"
                          sx={{ color: colors.red[600] }}
                        />
                      ),
                    }
                  }
                  error={!!errorText}
                  helperText={errorText}
                />
              )}
            />
          </div>
          <FormSpy
            onChange={() => {
              if (errorText) {
                clearErrors();
              }
            }}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={loading}
            fullWidth
            disabled={submitting || pristine}
          >
            {t("Auth.sing-in")}
          </LoadingButton>
        </form>
      )}
    />
  );
};
