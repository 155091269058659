import { createSlice } from "@reduxjs/toolkit";
import { getGames } from "./thunks";
import { RootState } from "..";
import { IGame } from "../../entities/games";


interface GamesState {
  games: IGame[]
  loading: boolean,
  loadingError: boolean,
}

const initialState: GamesState = {
  games: [],
  loading: false,
  loadingError: false,
}

export const gamesSlice = createSlice({
  name: "games",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getGames.pending, (state) => {
      state.loading = true;
      state.loadingError = false;
    })
    builder.addCase(getGames.fulfilled, (state, action) => {
      state.games = action.payload;
      state.loading = false;
      if (action.payload.length === 0) {
        state.loadingError = true;
      }
    })
    builder.addCase(getGames.rejected, (state) => {
      state.loading = false;
      state.loadingError = true;
    })
  },
});

// export const { } = gamesSlice.actions;

export const selectGames = (state:RootState) => state.games 

export default gamesSlice.reducer;
