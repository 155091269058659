import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { colors } from "../../Theme/ColorsForTheam";

export const ArrowBottom = ({ open }: { open: boolean }) => {
  return (
    <ArrowBackIosNewIcon
      sx={
        !open
          ? {
            transform: "rotate(-90deg)",
            transition: "all .3s",
            fontSize: "13px",
          }
          : {
            transform: "rotate(90deg)",
            transition: "all .3s",
            fontSize: "13px",
            color: colors.blue[400],
          }
      }
    />
  )
}