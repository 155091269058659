import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getPrizeWheel, getProfile, getProfilesWheel } from "./thunks";
import { IDatabaseWheel, IGlobalProfile, IProfilesWheel, ITranslationProfile, IWheelConfige } from "../../entities/wheelGame";
import { RootState } from "..";

interface WheelState {
  loading: boolean,
  loadedProfile: boolean,
  prizes: IDatabaseWheel[],
  noPrizes: boolean,
  profiles: IProfilesWheel[],
  noProfiles: boolean,
  editGlobalProfile?: IGlobalProfile,
  wheelConfige: IWheelConfige[],
  translations: ITranslationProfile[],
}

const initialState: WheelState = {
  prizes: [],
  profiles: [],
  loading: false,
  loadedProfile: false,
  noPrizes: false,
  noProfiles: false,
  wheelConfige: [],
  translations: [],
}

export const wheelSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    setGlobalProfile: (state, action: PayloadAction<IGlobalProfile>) => {
      state.editGlobalProfile = action.payload
    },
    unsetGlobalProfile: (state) => {
      delete state.editGlobalProfile
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPrizeWheel.pending, (state) => {
      state.loading = true;
      state.noPrizes = false;
    })
    builder.addCase(getPrizeWheel.fulfilled, (state, action) => {
      state.loading = false;
      state.prizes = action.payload;
      if (action.payload.length === 0) {
        state.noPrizes = true;
      }
    })

    builder.addCase(getProfilesWheel.pending, (state) => {
      state.loading = true;
      state.noProfiles = false
    })
    builder.addCase(getProfilesWheel.fulfilled, (state, action) => {
      state.loading = false;
      state.profiles = action.payload;
      if (action.payload.length === 0) {
        state.noProfiles = true;
      }
    })

    builder.addCase(getProfile.pending, (state) => {
      state.loading = true;
    })

    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.loading = false;
      const { config, global, translations } = action.payload;
      if (global) {
        state.editGlobalProfile = global;
      }
      state.wheelConfige = config;
      state.translations = translations;

      state.loading = false;
      state.loadedProfile = true;
    })

    // builder.addCase(createGlobalProfile.pending, (state) => {
    //   state.loadingProfile = true;
    // })

    // builder.addCase(createGlobalProfile.fulfilled, (state, action) => {
    //   state.editGlobalProfile = action.payload;
    //   state.loadingProfile = false;
    // })

    // builder.addCase(getConfigWheelById.fulfilled, (state, action) => {
    //   if (action.payload.length > 1) {
    //     state.wheelConfige = action.payload;
    //   } else {
    //     state.wheelConfige = [];
    //   }
    // })

    // builder.addCase(getPopupSettings.fulfilled, (state, action) => {
    //   state.translations = action.payload;
    // })
  },
});

export const { unsetGlobalProfile, setGlobalProfile } = wheelSlice.actions;

export const selectWheel = (state: RootState) => state.wheel;
export const selectPrizesState = (state: RootState) => state.wheel.prizes;
export const selectProfilessState = (state: RootState) => state.wheel.profiles;

// export const selectModifierBonus = (state: RootState) => state.bonus.modifier;

export default wheelSlice.reducer;
