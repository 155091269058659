import { Draft, PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../index";
import { IBonusData, ModifierType } from "../../entities/bonus";
import { createBonuse, getBonuses, updateBonuseById } from "./thunks";

type EditableBonus = { index: number } & IBonusData;

type ChangeModifierAction = {
  modifier: ModifierType;
  data?: EditableBonus;
};

interface BonusState {
  bonuses: IBonusData[];
  modifier: ModifierType;
  editableBonus?: EditableBonus;
  loading: boolean;
  loadingError: boolean;
  errorText?: string;
}

const initialState: BonusState = {
  bonuses: [],
  modifier: "default",
  loading: false,
  loadingError: false,
};

export const bonusSlice = createSlice({
  name: "bonus",
  initialState,
  reducers: {
    addedBonuse: (state: Draft<BonusState>) => {
      const newBonuse: IBonusData = {
        id: 0,
        customerBonusId: "",
        dateCreated: new Date().toISOString(),
        dateUpdated: new Date().toISOString(),
        name: "",
        status: "inactive",
      };
      state.bonuses.unshift(newBonuse);
      state.modifier = "create";
      state.editableBonus = { index: 0, ...state.bonuses[0] };
    },
    changeModifier: (
      state: Draft<BonusState>,
      action: PayloadAction<ChangeModifierAction>
    ) => {
      state.modifier = action.payload.modifier;
      if (action.payload.modifier === "edit" && action.payload.data) {
        state.editableBonus = action.payload.data;
      } else {
        state.editableBonus = undefined;
      }
    },
    cancelModifire: (state: Draft<BonusState>) => {
      if (state.modifier === "create") {
        state.bonuses.shift();
      }
      state.modifier = "default";
      state.editableBonus = undefined;
      state.errorText = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBonuses.pending, (state: Draft<BonusState>) => {
      state.loading = true;
      state.loadingError = false;
    });
    builder.addCase(
      getBonuses.fulfilled,
      (state: Draft<BonusState>, action: PayloadAction<IBonusData[]>) => {
        state.loading = false;
        state.bonuses = action.payload;
      }
    );
    builder.addCase(getBonuses.rejected, (state: Draft<BonusState>) => {
      state.loading = false;
      state.loadingError = true;
    });

    builder.addCase(updateBonuseById.fulfilled, (state) => {
      state.loading = false;
      state.editableBonus = undefined;
      state.modifier = 'default';
    })
    builder.addCase(updateBonuseById.rejected, (state, action) => {
      state.loading = false;
      state.errorText = action.error.message;
      if (state.editableBonus) {
        state.editableBonus = { ...state.editableBonus, name: action.meta.arg.name, customerBonusId: action.meta.arg.customerBonusId }
      }
    })

    // builder.addCase(createBonuse.pending, (state) => {
    //   state.loading = true;
    //   state.errorText = undefined;
    // });
    builder.addCase(createBonuse.fulfilled, (state) => {
      state.loading = false;
      state.editableBonus = undefined;
      state.modifier = 'default';
      state.errorText = undefined;
    });
    builder.addCase(createBonuse.rejected, (state, action) => {
      state.loading = false;
      state.errorText = action.error.message;
      if (state.editableBonus) {
        state.editableBonus = { ...state.editableBonus, name: action.meta.arg.name, customerBonusId: action.meta.arg.customerBonusId }
      }
    })
  },
});

export const { changeModifier, cancelModifire, addedBonuse } =
  bonusSlice.actions;

export const selectBonusState = (state: RootState) => state.bonus;
export const selectModifierBonus = (state: RootState) => state.bonus.modifier;

export default bonusSlice.reducer;
