import { createTheme } from "@mui/material";
import { colors } from "./ColorsForTheam";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export const GamixterTheam = createTheme({
  palette: {
    background: {
      default: "#FAFAFB",
    },
    primary: {
      main: colors.blue[900],
    },
    secondary: {
      main: colors.gray[300],
    },
  },
  typography: {
    fontFamily: "Roboto, sans-serif",
    h1: {
      color: colors.gray[900],
      fontSize: "28px",
      fontWeight: "600",
      lineHeight: "32.81px",
    },
    h2: {
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "28.96px",
    },
    h3: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "22px",
    },
    h4: {
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "21px",
    },
    body1: {
      fontSize: "14px",
      fontWeight: 400,
      lineHeight: "22px",
    },
    subtitle1: {
      fontSize: "12px",
      fontWeight: 500,
      lineHeight: "14px",
    },
    subtitle2: {
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${colors.gray[200]}`,
          },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: `1px solid ${colors.gray[400]}`,
          },
          "&.MuiOutlinedInput-root .MuiInputBase-input:focus ~ .MuiOutlinedInput-notchedOutline":
            {
              border: `1px solid ${colors.blue[400]}`,
            },
          "&.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":
            {
              border: `1px solid ${colors.red[600]}`,
            },
        },
        input: {
          "&:-internal-autofill-selected": {
            background: "#fff",
            backgroundColor: "red !important",
            appearance: "none",
          },
          "&:focus": {},
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#fff",
        },
        input: {
          padding: "9px 16px",
          transition: "all .3s",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
          borderRadius: 4,
          background: "#fff",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginTop: 0,
          fontSize: "12px",
          fontWeight: 400,
          lineHeight: "20px",
          "&.Mui-error": {
            color: colors.red[600],
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: "initial",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "22px",
          padding: "10px 0px",
          background: colors.blue[500],
          "&:hover": {
            background: colors.blue[600],
          },
          "&:disabled": {
            background: colors.gray[100],
            color: "#ffffff",
          },
        },
        outlined: {
          fontSize: 14,
          lineHeight: "22px",
          textTransform: "initial",
          border: `1px solid ${colors.blue[400]}`,
          color: colors.blue[400],
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 8px 20px 0px #00000014, 0px 12px 32px 4px #0000000A",
          border: `1px solid ${colors.gray[100]}`,
          borderRadius: 4,
          background: "#ffffff",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "9px 20px",
          "&:hover": {
            background: "#F5F7FA",
          },
          "&:active": {
            background: "#EFF3FF",
          },
          "&.Mui-disabled": {
            opacity: 0.56,
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          "& svg": {
            width: 20,
            height: 20,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "22px",
          color: colors.gray[900],
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: "13px 8px",
          borderColor: colors.gray[50],
          overflow: "hidden",
          whiteSpace: "nowrap",
          color: colors.blue[900],
        },
        head: {
          color: colors.gray[300],
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16.41px",
          textTransform: "uppercase",
          whiteSpace: "nowrap",
          border: "none",
          padding: "16px 8px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "9px 20px",
          fontSize: "14px",
          fontWeight: 500,
          lineHeight: "22px",
          textTransform: "initial",
          color: colors.gray[400],
          border: `1px solid ${colors.gray[100]}`,
          borderBottom: "none",
          minHeight: "22px",
          borderRight: "none",
          "&:last-of-type": {
            borderRight: `1px solid ${colors.gray[100]}`,
          },
          "&.Mui-selected": {
            color: "#ffffff",
            background: colors.blue[500],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.gray[100]}`,
          minHeight: "22px",
          "& .MuiTab-root:first-of-type": {
            borderRadius: "4px 0px 0px 0px",
          },
          "& .MuiTab-root:last-of-type": {
            borderRadius: "0px 4px 0px 0px",
          },
        },
        indicator: {
          display: "none",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          transform: "none",
          position: "relative",
          marginBottom: 8,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          outline: "none",
          boxSizing: "border-box",
          "& .MuiOutlinedInput-notchedOutline legend": {
            display: "none",
          },
          "&.Mui-focused": {
            border: `1px solid ${colors.blue[400]}`,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            height: "38px",
            top: 0,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
        select: {
          minHeight: "38px",
          padding: 0,
          "&:focus-visible": {
            border: "none",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            padding: 0,
            height: "38px",
          },
          "& .MuiOutlinedInput-root": {
            padding: 0,
            background: "#fff",
          },
        },
        inputRoot: {
          padding: 0,
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&::after,&::before": {
            display: "none",
          },
        },
        underline: {
          border: "none",
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          "&::before": {
            display: "none",
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        asterisk: {
          display: "none",
        },
      },
    },
  },
});
