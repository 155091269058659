import { createSlice } from "@reduxjs/toolkit";
import { getTriggers } from "./thunks";
import { ITriggerData } from "../../entities/trigger";
import { RootState } from "..";

interface TriggerState {
  triggers: ITriggerData[]
  loading: boolean,
  loadingError: boolean,
}

const initialState: TriggerState = {
  triggers: [],
  loading: false,
  loadingError: false,
}

export const triggerSlice = createSlice({
  name: "tigger",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(getTriggers.pending, (state) => {
      state.loading = true;
      state.loadingError = false;
    })
    builder.addCase(getTriggers.fulfilled, (state, action) => {
      state.triggers = action.payload;
      state.loading = false;
      if (action.payload.length === 0) {
        state.loadingError = true;
      }
    })
    builder.addCase(getTriggers.rejected, (state) => {
      state.loading = false;
      state.loadingError = true;
    })
  },
});

// export const { } = triggerSlice.actions;

export const selectTriggerState = (state: RootState) => state.trigger;
// export const selectModifierBonus = (state: RootState) => state.bonus.modifier;

export default triggerSlice.reducer;
