import styled from "@emotion/styled";
import { Avatar } from "@mui/material";
import { Link } from "react-router-dom";
import { NavBarInProfile } from "../navigation/NavBar";
import { BreadCrumbs } from "../Components/Breadcrumbs/Breadcrumbs";

const AdminHeader = styled.header`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 1000;
  width: 100%;
  height: 72px;
  background: #ffffff;
  box-shadow: 0px 8px 16px 0px #aab4bf26;
  display: flex;
  align-items: center;
`;
const Container = styled.div`
  width: 1680px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 1700px) {
    width: 98%;
  }
`;
const Main = styled.main`
  width: 1680px;
  margin: 0px auto;
  @media screen and (max-width: 1700px) {
    width: 98%;
  }
`;
const Header = () => {
  return (
    <AdminHeader>
      <Container>
        <Link to="/">
          <Avatar
            variant="rounded"
            sx={{
              width: 60,
              height: 60,
              boxShadow: "0px 0px 16px 0px #0000001A",
              background: "#fff",
              borderRadius: "12px",
              "& svg path": {
                fill: "gray",
              },
            }}
          />
        </Link>
        <NavBarInProfile />
      </Container>
    </AdminHeader>
  );
};

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <Header />
      <BreadCrumbs />
      <Main>{children}</Main>
    </>
  );
};
