import { SvgIcon } from "@mui/material";

export const IntegrationIcon = () => {
  return (
    <SvgIcon>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
      >
        <g clipPath="url(#clip0_994_9145)">
          <path
            d="M11.311 6.72923H10.5547C10.5402 6.72923 10.5263 6.72347 10.516 6.71321C10.5058 6.70296 10.5 6.68905 10.5 6.67454V4.56251C10.5 4.2807 10.388 4.01043 10.1888 3.81116C9.98951 3.61189 9.71924 3.49994 9.43742 3.49994H7.32539C7.31089 3.49994 7.29698 3.49417 7.28672 3.48392C7.27646 3.47366 7.2707 3.45975 7.2707 3.44525V2.68892C7.2707 1.94681 6.68281 1.32556 5.9407 1.31271C5.7609 1.30955 5.58227 1.34223 5.41524 1.40884C5.2482 1.47545 5.09611 1.57466 4.96782 1.70068C4.83954 1.8267 4.73763 1.97701 4.66806 2.14283C4.59848 2.30865 4.56262 2.48667 4.56258 2.6665V3.44525C4.56258 3.45975 4.55682 3.47366 4.54656 3.48392C4.5363 3.49417 4.52239 3.49994 4.50789 3.49994H2.39586C2.1088 3.5008 1.83375 3.61522 1.63076 3.8182C1.42778 4.02118 1.31337 4.29624 1.3125 4.58329V6.50775C1.3125 6.52225 1.31826 6.53616 1.32852 6.54642C1.33877 6.55667 1.35268 6.56244 1.36719 6.56244H2.10301C2.90609 6.56244 3.57082 7.25915 3.58203 8.06224C3.59352 8.879 2.93918 9.62494 2.12488 9.62494H1.36719C1.35268 9.62494 1.33877 9.6307 1.32852 9.64095C1.31826 9.65121 1.3125 9.66512 1.3125 9.67962V11.6041C1.31337 11.8911 1.42778 12.1662 1.63076 12.3692C1.83375 12.5722 2.1088 12.6866 2.39586 12.6874H4.32031C4.33482 12.6874 4.34873 12.6817 4.35898 12.6714C4.36924 12.6612 4.375 12.6473 4.375 12.6327V12.0604C4.375 11.2325 5.05176 10.5196 5.87891 10.5002C6.70195 10.4811 7.4375 11.0556 7.4375 11.8751V12.6327C7.4375 12.6473 7.44326 12.6612 7.45352 12.6714C7.46377 12.6817 7.47768 12.6874 7.49219 12.6874H9.43742C9.71924 12.6874 9.98951 12.5755 10.1888 12.3762C10.388 12.1769 10.5 11.9067 10.5 11.6249V9.49204C10.5 9.47754 10.5058 9.46363 10.516 9.45338C10.5263 9.44312 10.5402 9.43736 10.5547 9.43736H11.3334C12.0889 9.43736 12.6875 8.81747 12.6875 8.05923C12.6875 7.30099 12.0531 6.72923 11.311 6.72923Z"
            stroke="#6D6D6D"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_994_9145">
            <rect width="14" height="14" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
