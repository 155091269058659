import { createAsyncThunk } from "@reduxjs/toolkit";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { auth } from "../../hooks/useAuth";
import { logOut } from "./authStore";
import { setRefreshToken, setToken } from "../../api";

export const firebaseAuthUser = createAsyncThunk(
  'auth/userProfile',
  async (data: { login: string, password: string }, thunkApi) => {
    const profile = signInWithEmailAndPassword(auth, data.login, data.password)
      .then((userCredential) => {
        const user = userCredential.user;
        return user.getIdToken()
          .then((token) => {
            return {
              uid: user.uid,
              email: user.email!,
              token,
              refresh: user.refreshToken
            }
          })
      })
    const req = await profile;
    if (!req) {
      return null;
    }
    setToken(req.token);
    setRefreshToken(req.refresh);
    return { uid: req.uid, email: req.email }
  }
);

export const userLogOut = createAsyncThunk(
  'auth/userLogOut',
  async (_, thunkApi) => {
    thunkApi.dispatch(logOut());
    signOut(auth);
  }
);