import { Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AuthBox, AuthTitle, SectionAuth } from "./styled";
import { AuthForm } from "./AuthForm";

export const AuthPage = () => {
  const { t } = useTranslation();
  return (
    <SectionAuth>
      <AuthBox>
        <Avatar
          variant="rounded"
          sx={{
            marginBottom: "45px",
            width: 60,
            height: 60,
            boxShadow: "0px 0px 16px 0px #0000001A",
            background: "#fff",
            borderRadius: '12px',
            '& svg path': {
              fill: 'gray'
            }
          }}
        />
        <AuthTitle>{t("Auth.sing-in")}</AuthTitle>
        <AuthForm />
      </AuthBox>
    </SectionAuth>
  );
};
