import { SvgIcon } from "@mui/material";

export const StarIcon = () => {
  return (
    <SvgIcon>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9131 17.1323L17.2569 19.9448C17.3194 19.9603 17.3741 19.9525 17.4211 19.9215C17.4681 19.8905 17.4839 19.8435 17.4684 19.7805L16.4604 13.827L20.7729 9.60825C20.8199 9.56125 20.8356 9.5105 20.8201 9.456C20.8046 9.4015 20.7656 9.36625 20.7031 9.35025L14.7264 8.48325L12.0541 3.069C12.0226 3.0065 11.9756 2.97525 11.9131 2.97525C11.8506 2.97525 11.8036 3.0065 11.7721 3.069L9.09986 8.48325L3.12311 9.35025C3.06061 9.36575 3.02161 9.401 3.00611 9.456C2.99061 9.511 3.00636 9.56175 3.05336 9.60825L7.36586 13.827L6.33461 19.7805C6.33461 19.843 6.35811 19.89 6.40511 19.9215C6.45211 19.953 6.50686 19.9608 6.56936 19.9448L11.9131 17.1323ZM7.27286 21.2573C6.97586 21.4138 6.67511 21.4803 6.37061 21.4568C6.06611 21.4333 5.78486 21.3278 5.52686 21.1403C5.26886 20.9528 5.08136 20.7145 4.96436 20.4255C4.84736 20.1365 4.81211 19.8358 4.85861 19.5233L5.74961 14.3438L1.99961 10.6875C1.76511 10.4685 1.61286 10.2068 1.54286 9.90225C1.47286 9.59775 1.48861 9.293 1.59011 8.988C1.69161 8.683 1.85961 8.433 2.09411 8.238C2.32861 8.043 2.60211 7.922 2.91461 7.875L8.11811 7.125L10.4386 2.41425C10.5791 2.11725 10.7824 1.89075 11.0484 1.73475C11.3144 1.57875 11.6034 1.5005 11.9154 1.5C12.2274 1.4995 12.5164 1.57775 12.7824 1.73475C13.0484 1.89175 13.2516 2.11825 13.3921 2.41425L15.7126 7.125L20.9161 7.875C21.2286 7.922 21.5059 8.043 21.7479 8.238C21.9899 8.433 22.1579 8.683 22.2519 8.988C22.3459 9.293 22.3576 9.59775 22.2871 9.90225C22.2166 10.2068 22.0644 10.4685 21.8304 10.6875L18.0804 14.3438L18.9714 19.5233C19.0184 19.8358 18.9831 20.1365 18.8656 20.4255C18.7481 20.7145 18.5606 20.9528 18.3031 21.1403C18.0456 21.3278 17.7644 21.4333 17.4594 21.4568C17.1544 21.4803 16.8536 21.4217 16.5571 21.2812L11.9161 18.8205L7.27286 21.2573Z"
          fill="#6D6D6D"
        />
      </svg>
    </SvgIcon>
  );
};
