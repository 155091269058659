import { createAsyncThunk } from "@reduxjs/toolkit";
import { agent } from "../../api";
import { apiUrlV1 } from "../../config/config";
import { gamesMiddleware } from "../../middleware/gameMiddleware";
import { IGame } from "../../entities/games";


export const getGames = createAsyncThunk(`games/list`,
  async () => {
    const res = await agent.get(`${apiUrlV1}/organization/game/list`);
    if (res.ok) {
      const body = await res.json();
      return (gamesMiddleware(body) as IGame[]);
    }
    return [];
  }
)